import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Popover, Dropdown, Radio, Menu, Tooltip } from 'antd';
import { Auth } from 'utils';

import styles from './BasicLayout.module.css';
import { get } from 'lodash';
import { USER_CENTER_URL, LOCAL_URL, PUBLIC_URL, WENZHOU, HANGZHOU, SANMING, ALIYUN, HUAWEIYUN } from 'constants/env';
import GlobalContext from 'globalContext';
import ATTENTION_IMG from 'statics/images/attentionWER.jpg';
import TOP_ICON from 'statics/images/topIcon.png';
import PHONE_ICON from 'statics/images/phoneIcon.png';

import { cityDataMap } from '../cityDataMap';

import { FONT_SIZE_BASE, PRIMARY_COLOR } from 'constants/styleVariables';
import LOGO from 'statics/images/home/logo.png';

import PageFooter from './PageFooter';
import { _getAreaTree } from './_api';
import QRCode from 'qrcode.react';
import { DownOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const commonSize = 100;
const radioStyle = {
  fontSize: 10,
};
const commonStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: 20,
};

export default function BasicLayout(props) {
  const history = useHistory();
  const [topVisible, setTopVisible] = useState(false);
  const [stuIosVisible, setStuIosVisible] = useState(true);
  const [schIosVisible, setSchIosVisible] = useState(true);
  const [stuAndroidVisible, setStuAndroidVisible] = useState(false);
  const [schAndroidVisible, setSchAndroidVisible] = useState(false);
  const [stuValue, setStuValue] = useState(0);
  const [schValue, setSchValue] = useState(2);
  const [isLogin, setIsLogin] = useState(false);
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const { $currentCity, $setCurrentCity, $stuAppData, $schoolAppData, $token, $schoolId } = useContext(GlobalContext);

  useEffect(() => {
    if (!Auth.isAuthenticated()) {
      // 用户点击【登录】 | 进入【网络学习】页面时强制进入登录页
      (isLogin ||
        history.location.pathname.includes('theory/myCourse') ||
        history.location.pathname.includes('theory/learnRecord') ||
        history.location.pathname.includes('exam')) &&
        history.push(`${PUBLIC_URL}login`);
    }
  }, [$token, history.location.pathname, isLogin]);

  useEffect(() => {
    document.addEventListener('click', () => setDropDownVisible(false)); //点击其他地方，隐藏地址选择框
    window.addEventListener('scroll', topEvent);
    function topEvent() {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        setTopVisible(true);
      } else {
        setTopVisible(false);
      }
    }
    return () => window.removeEventListener('scroll', topEvent);
  }, []);

  const PATH_ARR = [
    { path: `${PUBLIC_URL}home`, title: '首页' },
    { path: `${PUBLIC_URL}product`, title: '产品中心' },
  ];
  Auth.isAuthenticated() && Auth.get('isStudent') && $schoolId
    ? PATH_ARR.push(
        { path: `${PUBLIC_URL}theory/myCourse`, title: '网络学习' }, // 登录后才能看到
        { path: `${PUBLIC_URL}about`, title: '关于我们' },
        { path: `${PUBLIC_URL}question`, title: '常见问题' },
      )
    : PATH_ARR.push(
        { path: `${PUBLIC_URL}about`, title: '关于我们' },
        { path: `${PUBLIC_URL}question`, title: '常见问题' },
      );

  function _handleLogout() {
    const token = Auth.get('token');
    window.location.href = `${USER_CENTER_URL}/uni/cusLogout?token=${token}&redirect_uri=${LOCAL_URL}${PUBLIC_URL}home`;
    Auth.del();
  }

  function _handleLogin() {
    setIsLogin(true);
  }

  function _handleClick(item, index) {
    history.push(item.path);
  }

  const ATTENTION = (
    <div style={{ textAlign: 'center' }}>
      <img src={ATTENTION_IMG} style={{ width: 120 }} />
      <div style={{ color: '#aaa', margin: '5px 0' }}>关注我们</div>
    </div>
  );

  function handleStuChange(e) {
    if (e.target.value == 0) {
      setStuIosVisible(true);
      setStuAndroidVisible(false);
    }
    if (e.target.value == 1) {
      setStuIosVisible(false);
      setStuAndroidVisible(true);
    }
    setStuValue(e.target.value);
  }

  function handleSchChange(e) {
    if (e.target.value == 2) {
      setSchIosVisible(true);
      setSchAndroidVisible(false);
    }
    if (e.target.value == 3) {
      setSchIosVisible(false);
      setSchAndroidVisible(true);
    }
    setSchValue(e.target.value);
  }
  const DOWNLOADAPP = (
    <div style={{ display: 'flex' }}>
      <div style={commonStyle}>
        {stuIosVisible && <QRCode value={get($stuAppData, 'ios.appDownloadUrl', '') || ''} size={commonSize} />}
        {stuAndroidVisible && <QRCode value={get($stuAppData, 'android.appDownloadUrl', '') || ''} size={commonSize} />}
        <span style={{ margin: '5px 0px' }}>学员版</span>
        <Radio.Group value={stuValue} onChange={handleStuChange} buttonStyle="solid">
          <Radio.Button value={0} style={radioStyle}>
            ios
          </Radio.Button>
          <Radio.Button value={1} style={radioStyle}>
            Android
          </Radio.Button>
        </Radio.Group>
      </div>
      <div style={commonStyle}>
        {schIosVisible && <QRCode value={get($schoolAppData, 'ios.appDownloadUrl', '') || ''} size={commonSize} />}
        {schAndroidVisible && (
          <QRCode value={get($schoolAppData, 'android.appDownloadUrl', '') || ''} size={commonSize} />
        )}
        <span style={{ margin: '5px 0px' }}>驾服版</span>
        <Radio.Group value={schValue} onChange={handleSchChange} buttonStyle="solid">
          <Radio.Button value={2} style={radioStyle}>
            ios
          </Radio.Button>
          <Radio.Button value={3} style={radioStyle}>
            Android
          </Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
  const menu = (
    <Menu>
      <Menu.Item onClick={_handleLogout} style={{ cursor: 'pointer' }}>
        退出
      </Menu.Item>
    </Menu>
  );
  function scrollToAnchor(anchorName) {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }

  const CITY_CODE_LINK = {
    330300: 'http://124.70.184.157:33035/', // 温州
    330600: 'http://119.3.5.53:13306', // 绍兴
    331000: 'http://sn.welldrive.cn:9999/', // 台州
    330400: 'http://sn.welldrive.cn:23304/', // 嘉兴
    330500: 'http://124.70.184.157:13305/', // 湖州
    330100: 'http://hangzhou.welldrive.cn:8060', // 杭州
    341000: 'http://124.71.163.246:8030', // 黄山
  };
  function linkPush(b) {
    window.open(b);
  }

  const cityDataArr = Object.keys(cityDataMap);
  const cityContent = (
    <div
      style={{
        background: 'white',
        padding: 10,
        position: 'fixed',
        top: 60,
        left: 150,
        color: '#999999',
      }}
      id="cityContent"
      onClick={(e) => {
        e.nativeEvent.stopImmediatePropagation(); //阻止冒泡，防止点击地址选择框本身也会隐藏
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {cityDataArr.map((x) => {
          return (
            <div
              key={x}
              onClick={() => {
                setDropDownVisible(true);
                scrollToAnchor(x);
              }}
              style={{
                border: '1px solid #6f6d6d33',
                width: 20,
                cursor: 'pointer',
                textAlign: 'center',
              }}
            >
              {x}
            </div>
          );
        })}
      </div>
      <div style={{ height: 300, overflow: 'auto', margin: 10 }}>
        {cityDataArr.map((x) => {
          return (
            <div style={{ display: 'flex' }} key={x}>
              <div style={{ width: 50, fontSize: 16, fontWeight: 500 }} id={x}>
                {x}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {cityDataMap[x].map((province) => {
                  return (
                    <div style={{ display: 'flex', margin: '5px 0' }} key={province.value}>
                      <div style={{ width: 100 }}>{province.value}</div>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'start',
                          width: 400,
                        }}
                      >
                        {get(province, 'children', []).map((city) => {
                          return (
                            <div
                              key={city.value}
                              className={styles.cityItem}
                              onClick={() => {
                                $setCurrentCity([
                                  {
                                    code: get(province, 'key', ''),
                                    name: get(province, 'value', ''),
                                  },
                                  { code: get(city, 'key', ''), name: get(city, 'value', '') },
                                ]);
                                setDropDownVisible(false);
                                // 温州：http://124.70.184.157:33035/  330300
                                // 绍兴：http://119.3.5.53:13306  330600
                                // 台州：http://sn.welldrive.cn:9999/ 331000
                                // 嘉兴：http://202.91.245.28:8047 330400
                                // 湖州：http://124.70.184.157:13305/ 330500
                                // 杭州：http://hangzhou.welldrive.cn:8060  330100
                                // 黄山：http://124.71.163.246:8030  341000
                                // 如果是上面城市，则跳转到对应的地址
                                // if (Object.keys(CITY_CODE_LINK).includes(get(city, 'key', ''))) {
                                //   window.open(CITY_CODE_LINK[get(city, 'key', '')]);
                                // }

                                // // 如果是（海南46、广东44、甘肃62、河北13、河南41）的省份下的城市，跳转到http://jxpt.welldrive.cn:30000/
                                // const provinceCode = ['46', '44', '62', '13', '41']; // 包含的城市
                                // const cityCodeInProvince = get(city, 'key', '').slice(0, 2); // 选中城市，当前所在省份
                                // if (provinceCode.includes(cityCodeInProvince)) {
                                //   window.open('http://jxpt.welldrive.cn:30000/');
                                // }
                                // 温州：https://wenzhou.yfdrive.cn  330300
                                // 三明：https://sanming.yfdrive.cn 350400
                                // 杭州：https://hangzhou.yfdrive.cn 330100
                                // 如果是上面城市，则跳转到对应的地址
                                if (city.key === '330300') {
                                  linkPush(WENZHOU);
                                  return;
                                } else if (city.key === '350400') {
                                  linkPush(SANMING);
                                  return;
                                } else if (city.key === '330100') {
                                  linkPush(HANGZHOU);
                                  return;
                                } else if (city.key === '331100') {
                                  linkPush(`${HUAWEIYUN}/school/`);
                                  return;
                                }
                                // 浙江省内 阿里云
                                if (province.key.indexOf('33') === 0) {
                                  linkPush(`${ALIYUN}/school/`);
                                } else {
                                  // 省外华为云
                                  linkPush(`${HUAWEIYUN}/school/`);
                                }
                              }}
                            >
                              {get(city, 'value')}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
  return (
    <div>
      <div style={{ position: 'fixed', bottom: 100, right: '5%', zIndex: 100 }}>
        {dropDownVisible && cityContent}
        {topVisible && (
          <div
            className={styles.bottomBox}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <img className={styles.bottomIcon} src={TOP_ICON} />
            返回顶部
          </div>
        )}

        <Popover
          content={ATTENTION}
          placement="left"
          trigger="hover"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <div className={styles.bottomBox}>
            <img className={styles.bottomIcon} src={PHONE_ICON} />
            关注我们
          </div>
        </Popover>

        <Popover
          content={DOWNLOADAPP}
          placement="left"
          trigger="hover"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <div className={styles.bottomBox}>
            <img className={styles.bottomIcon} src={PHONE_ICON} />
            下载客户端
          </div>
        </Popover>
      </div>

      <Layout
        className="ie-basic-layout-wrapper"
        style={{ minHeight: '100vh', background: 'rgba(248,248,248,1)', minWidth: 1200 }}
      >
        <Header
          style={{
            padding: 0,
            background: '#fff',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 100,
            width: '100%',
          }}
        >
          <div className={styles.headerBox}>
            <div style={{ flex: 1, minWidth: 400 }}>
              <span
                style={{ marginRight: 20 }}
                onClick={() => {
                  history.push(`${PUBLIC_URL}home`);
                }}
              >
                {
                  <img
                    src={LOGO}
                    alt=""
                    style={{ width: 140 }}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      });
                    }}
                  />
                }
              </span>
              <span
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                  setDropDownVisible(!dropDownVisible);
                }}
              >
                <span style={{ fontSize: 12 }}>{get($currentCity, '1.name') || get($currentCity, '0.name')}</span>
                <span style={{ cursor: 'pointer', marginLeft: 10, fontSize: 12 }}>
                  切换 <DownOutlined />
                </span>
              </span>
            </div>
            <div className={styles.navTab} style={{ fontSize: FONT_SIZE_BASE, flex: 2, minWidth: 400 }}>
              {PATH_ARR.map((item, index) => {
                return (
                  <span
                    key={index}
                    onClick={() => _handleClick(item, index)}
                    className={get(history, 'location.pathname') === PATH_ARR[index].path ? styles.tabLIne : null}
                  >
                    {item.title}
                  </span>
                );
              })}
            </div>
            <div style={{ textAlign: 'right', display: 'flex', minWidth: 370 }}>
              <span style={{ marginRight: 20, fontSize: 18 }}>
                服务热线：
                <span style={{ color: PRIMARY_COLOR, fontWeight: 'bold', fontSize: 18 }}>0571－28080500</span>
              </span>
              {!Auth.isAuthenticated() && (
                <span onClick={_handleLogin} style={{ cursor: 'pointer' }}>
                  登录
                </span>
              )}
              {Auth.isAuthenticated() && (
                <Dropdown overlay={menu}>
                  <span style={{ cursor: 'pointer' }} className={styles.dropdown}>
                    <span className={styles.ellipse}>
                      <Tooltip
                        title={Auth.get('stuName') ? Auth.get('stuName') : Auth.get('operatorName')}
                        placement="left"
                      >
                        {Auth.get('stuName') ? Auth.get('stuName') : Auth.get('operatorName')}
                      </Tooltip>
                    </span>
                    <DownOutlined style={{ alignSelf: 'center', fontSize: 12 }} />
                  </span>
                </Dropdown>
              )}
            </div>
          </div>
        </Header>
        <Content style={{ marginTop: 64 }} className="ie_content_bg">
          {props.children}
        </Content>
        <PageFooter />
      </Layout>
    </div>
  );
}
