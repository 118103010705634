import React, { useContext } from 'react';
import styles from './BasicLayout.module.css';
import { get, last } from 'lodash';
import { message } from 'antd';
import { LINKS } from '../constants/enums';
import FOOTER_LOGO from 'statics/images/home/footerLogo.png';
import TEL from 'statics/images/home/tel.png';
import FAX from 'statics/images/home/fax.png';
import QR_CODE from 'statics/images/attentionWER.jpg';
import BEIAN from 'statics/images/beian.png';
import { useVisible } from 'hooks';
import Timing from 'components/Timing';
import { _ssoLogin, _login } from './_api';
import { Auth } from 'utils';
import GlobalContext from 'globalContext';
import moment from 'moment';

export default function PageFooter() {
  const { $jpsUserId, $currentCity, $cityCode, $userId } = useContext(GlobalContext);
  const [timingVisible, _switchTimingVisible] = useVisible();
  const currentYear = moment().format('YYYY');

  // 计时平台登录
  async function _handleTiming() {
    if ($jpsUserId) {
      const res = await _ssoLogin({ cityCode: $cityCode, userId: $userId });
      if (get(res, 'resultCode') === '0000') {
        window.open(get(res, 'resultData'));
        return null;
      }
      _switchTimingVisible();
    } else {
      _switchTimingVisible();
    }
  }

  return (
    <>
      {timingVisible && <Timing onCancel={_switchTimingVisible} />}

      <div className={styles.footIntroduce}>
        <div className={styles.footMain}>
          <div className={styles.footerLogo}>{<img src={FOOTER_LOGO} alt="" style={{ width: 136 }} />}</div>
          <div className={styles.footerInfo}>
            <div>
              <div>产品中心</div>
              <div>
                <div>
                  <a target="_blank" rel="noopener noreferrer" className={styles.aLink} href={LINKS.THEORY_PLATFORM}>
                    在线理论培训平台
                  </a>
                </div>
                <div>
                  <a target="_blank" rel="noopener noreferrer" className={styles.aLink} href={LINKS.ERP}>
                    驾校管理平台
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div>联系我们</div>
              <div className={styles.contactWay}>
                <div>
                  <span>{<img src={TEL} alt="" style={{ width: 14 }} />} </span>
                  <span>电话</span>
                  <span>0571-28080500</span>
                </div>
                <div>
                  <span>{<img src={FAX} alt="" style={{ width: 14 }} />}</span>
                  <span>传真</span>
                  <span>0571-86696433</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.qrCode}>{<img src={QR_CODE} alt="" style={{ width: 105 }} />}</div>
        </div>
      </div>
      <div className={styles.footWords}>
        <div>版权所有: ©杭州远方智慧科技有限公司 {currentYear} 版权所有</div>
        <div>
          <span
            style={{ cursor: 'pointer', marginLeft: 6 }}
            onClick={() => {
              window.open('https://beian.miit.gov.cn');
            }}
          >
            浙ICP备2021013236号-2
          </span>
        </div>
      </div>
    </>
  );
}
