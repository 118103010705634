import { ERP_URL, PUBLIC_URL } from 'constants/env';

export const LINKS = {
  THEORY_PLATFORM: `${PUBLIC_URL}login`,
  ERP: ERP_URL, // 驾校管理系统
  ZHE_RECORD: 'http://beian.miit.gov.cn', // 浙公网安备

  // 产品与服务
  ERP_STUDENT: 'https://android.myapp.com/myapp/detail.htm?apkName=com.qx.student&ADTAG=mobile', // 维尔驾服学员版
  ERP_COACH: 'https://android.myapp.com/myapp/detail.htm?apkName=com.qx.coach&ADTAG=mobile', // 维尔驾服教练版
};

export const BANNER_DATA = [
  { value: 1999, label: '公司成立时间', unit: '年' },
  { value: 798, label: '累计服务学员', unit: '万' },
  { value: 5108, label: '合作驾校', unit: '+' },
  { value: 20108, label: '助力教练', unit: '+' },
  { value: 108, label: '专利成果', unit: '+' },
];

export const TIMING_WHITE_LIST = ['330100'];
