/**
 * 设置每日定时任务
 * @param {*} hour 小时
 * @param {*} minute 分钟
 * @param {*} callTask 任务函数
 **/
export function scheduledTask(hour, minute, callTask) {
  let taskTime = new Date();
  taskTime.setHours(hour);
  taskTime.setMinutes(minute);
  taskTime.setSeconds(0);
  console.log(taskTime);
  let timeDiff = taskTime.getTime() - new Date().getTime(); // 获取时间差
  timeDiff = timeDiff > 0 ? timeDiff : timeDiff + 24 * 60 * 60 * 1000;
  setTimeout(function () {
    callTask(); // 首次执行
    setInterval(callTask, 24 * 60 * 60 * 1000); // 24小时为循环周期
  }, timeDiff);
}
