import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import 'normalize.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import './index.css';
import * as serviceWorker from './serviceWorker';
// import { routes } from './routes.js';
import Routers from './Routers';
import GlobalProvider from './GlobalProvider';
import OptionsProvider from './OptionsProvider';

ReactDOM.render(
  <BrowserRouter>
    <GlobalProvider>
      <ConfigProvider locale={zhCN}>
        <OptionsProvider>
          <Routers />
        </OptionsProvider>
      </ConfigProvider>
    </GlobalProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
