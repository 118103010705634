import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Auth } from 'utils';
import GlobalContext from './globalContext';
import { useFetch } from 'hooks';
import { _getUserInfo, _getAreaAuto, _getAppDownLoadUrl } from './_api';

export default function GlobalProvider(props) {
  const [menus, setMenus] = useState([]);
  const [elements, setElements] = useState([]);
  const [menuTree, setMenuTree] = useState([]);
  const [menuHashTable, setMenuHashTable] = useState({});
  const [elementsHashTable, setElementsHashTable] = useState({});
  const [$companyId, $setCompanyId] = useState(Auth.get('companyId'));
  const [$token, $setToken] = useState(Auth.get('token'));
  const [$userId, $setUserId] = useState(Auth.get('userId'));
  const [$schoolId, $setSchoolId] = useState(Auth.get('schoolId'));
  const [$username, $setUsername] = useState(Auth.get('username'));
  const [$schoolLabel, $setSchoolLabel] = useState(Auth.get('schoolLabel'));
  const [$jpsUserId, $setJpsUserId] = useState(Auth.get('jpsUserId'));
  const [$userIdString, $setUserIdString] = useState(Auth.get('userIdString'));
  const [$rolesIds, $setRolesIds] = useState(Auth.get('rolesIds'));
  const [$cityCode, $setCityCode] = useState(Auth.get('cityCode'));
  const [$currentCity, $setCurrentCity] = useState(null);
  const [$stuAppData, $stuStuAppData] = useState('');
  const [$schoolAppData, $setSchoolAppData] = useState('');
  const [$operatorName, $setOperatorName] = useState(Auth.get('operatorName'));
  const [$isStudent, $setIsStudent] = useState(false);

  useFetch({
    request: _getAreaAuto,
    callback: (data) => {
      $setCurrentCity([get(data, 'province'), get(data, 'city')]);
    },
  });

  useFetch({
    request: _getUserInfo,
    query: { category: 'ERP' },
    callback: (data) => {
      get(data, 'elements', []).forEach((x) => {
        elementsHashTable[get(x, 'code')] = get(x, 'id');
      });

      get(data, 'menus', []).forEach((x) => {
        menuHashTable[get(x, 'code')] = get(x, 'id');
      });

      setElements(get(data, 'elements', []));
      setMenus(get(data, 'menus', []));
      setElementsHashTable({ ...elementsHashTable });
      setMenuHashTable({ ...menuHashTable });
    },
    depends: [$companyId],
  });

  useFetch({
    request: _getAppDownLoadUrl,
    query: {
      appNameType: '0',
    },

    callback: (data) => {
      $stuStuAppData(data);
    },
  });

  useFetch({
    request: _getAppDownLoadUrl,
    query: {
      appNameType: '1',
    },
    callback: (data) => {
      $setSchoolAppData(data);
    },
  });

  return (
    <GlobalContext.Provider
      value={{
        menus,
        setMenus,
        elements,
        setElements,
        menuTree,
        setMenuTree,
        elementsHashTable,
        menuHashTable,
        $token,
        $setToken,
        $userId,
        $setUserId,
        $schoolId,
        $setSchoolId,
        $username,
        $setUsername,
        $schoolLabel,
        $setSchoolLabel,
        $jpsUserId,
        $setJpsUserId,
        $userIdString,
        $setUserIdString,
        $rolesIds,
        $setRolesIds,
        $cityCode,
        $setCityCode,
        $companyId,
        $setCompanyId,
        $operatorName,
        $setOperatorName,
        $currentCity,
        $setCurrentCity,
        $stuAppData,
        $stuStuAppData,
        $schoolAppData,
        $setSchoolAppData,
        $isStudent,
        $setIsStudent,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
