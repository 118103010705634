import QRCode from 'qrcode.react';
import React, { useContext } from 'react';
import GlobalContext from 'globalContext';
import { get } from 'lodash';

const commonSize = 100;

export function SchoolAppQRCode() {
  const { $schoolAppData } = useContext(GlobalContext);
  return (
    <div style={{ display: 'flex' }}>
      <div>
        <QRCode value={get($schoolAppData, 'ios.appDownloadUrl', '') || ''} size={commonSize} />
        <span style={{ display: 'flex', justifyContent: 'center' }}>ios版</span>
      </div>
      <div style={{ marginLeft: 20 }}>
        <QRCode value={get($schoolAppData, 'android.appDownloadUrl', '') || ''} size={commonSize} />
        <span style={{ display: 'flex', justifyContent: 'center' }}>Android版</span>
      </div>
    </div>
  );
}
