import { message } from 'antd';
import { get } from 'lodash';
import { formatQueryParams } from 'utils/formatQueryParams';
import { USER_CENTER_URL } from 'constants/env';
import { Auth } from 'utils';

const needFailedFeedbackRequest = [];
const needFeedBackRequest = [];
const whiteList = ['/uni/oauth/token'];

export async function request(path, query = {}, method = 'GET', payload = {}) {
  const customHeader = get(payload, 'customHeader', {}); // 自定义Header
  const responseType = get(payload, 'responseType', 'json');
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    schoolId: Auth.get('schoolId'),
    Authorization: Auth.get('token') ? 'bearer' + Auth.get('token') : '',
    username: Auth.get('username'),
    sid: Auth.get('sid'),
    operatorName: encodeURIComponent(Auth.get('operatorName') || ''),
    ...customHeader,
  };
  let url = USER_CENTER_URL + path;
  const { openApiToken, ...params } = query;

  const purePath = path.replace(/\?.*$/g, '');
  // if (!whiteList.includes(purePath) && !openApiToken) {
  //   return;
  // }

  // if (openApiToken && !whiteList.includes(purePath)) {
  //   Object.assign(headers, { Authorization: 'bearer' + openApiToken });
  // }

  const options = {
    method,
    // credentials: 'include',
    // mode: 'cors',
    headers,
    responseType,
  };

  if (method === 'POST') {
    Object.assign(options, { body: JSON.stringify(params) });
  }

  if (method === 'GET') {
    url = USER_CENTER_URL + path + formatQueryParams(params);
  }

  return fetch(url, options)
    .then(parseJSON)
    .then((response) => showFeedback(response, path))
    .catch((error) => {
      console.log(error);
    });
}

function showFeedback(response, path) {
  if (needFeedBackRequest.includes(path)) {
    if (get(response, 'success')) {
      message.success(get(response, 'msg'));
    } else {
      message.error(get(response, 'msg'));
    }
  }

  if (needFailedFeedbackRequest.includes(path)) {
    if (!get(response, 'success')) {
      message.error(get(response, 'msg'));
    }
  }

  return response;
}

function parseJSON(response) {
  return response.json();
}
export async function postRequestTemp(path, query) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (Auth.isAuthenticated()) {
    Object.assign(headers, { token: Auth.get('token') });
  }

  const options = {
    method: 'POST',
    headers,
  };

  return fetch(USER_CENTER_URL + path + formatQueryParams(query), options)
    .then(parseJSON)
    .catch((error) => {});
}
