import React from 'react';
import { Layout, Menu } from 'antd';
import Navigation from './Navigation';

const { Header, Content } = Layout;
const { SubMenu } = Menu;

const menus = [
  {
    code: 'market',
    title: '招生营销',
    href: null,
    icon: 'UserAddOutlined',
    type: 'dirt',
    orderNum: 0,
    description: null,
    path: '/market',
    module: null,
    category: 'ERP',
    parentCode: null,
    id: '27c12bb9-ec75-4594-bc63-4d4eab193941',
    parentId: '-1',
    children: [
      {
        code: 'forecastReview',
        title: '学员报名',
        href: null,
        icon: 'UserOutlined',
        type: 'menu',
        orderNum: 1,
        description: null,
        path: '/market/forecastReview',
        module: null,
        category: 'ERP',
        parentCode: null,
        id: 'f8951efb-2007-47e7-8707-faf79f31dbec',
        parentId: '27c12bb9-ec75-4594-bc63-4d4eab193941',
        children: [],
      },
    ],
  },
];

export default function TheoryLayout(props: any) {
  return (
    <Layout style={{ width: 1200, margin: '0 auto', height: 800, marginBottom: 100 }}>
      <Navigation menus={menus} collapsed={false} setCollapsed={() => {}} />
      <Content
        style={{
          margin: 0,
          padding: 20,
          maxHeight: 'calc(100vh - 64px)',
          // height: 'calc(100vh - 64px)',
          overflow: 'auto',
        }}
        className="ie_content_bg"
      >
        {props.children}
      </Content>
    </Layout>
  );
}
