import { useState } from 'react';

export const useVisible: any = () => {
  const [visible, setVisible] = useState(false);

  function _switchVisible() {
    setVisible(!visible);
  }

  return [visible, _switchVisible];
};
