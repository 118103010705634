import { _get } from 'utils';
import { _getCarVideo, _getCarVideoStatus, _stopCarVideo } from './_api';
import { debounce } from 'lodash';
import { CloseOutlined, ExpandOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { useCountdown, useInterval } from 'hooks';
import React from 'react';

export default function VideoComponent(props: any) {
  const {
    item,
    width = '100%',
    // index,
    urlObj,
    // setUrlObj,
    url,
    // setCount,
    setVideoDetailVisible,
    setVideoUrl,
    setCarObj,
  } = props;

  function hideMsg(id: string) {
    const ele: any = document.getElementById(id);
    ele.style.display = 'none';
  }

  const { count: _count, isCounting, setIsCounting } = useCountdown(10);
  const [delay, setDelay] = useState(0);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    const key = `video-${_get(item, 'carId', '')}`;
    const videoId = key;
    console.log('videoId', videoId);
    // const videoId = `video-${_get(element, 'carId', '')}-${_get(element, 'id', '')}`;
    var win: any = window as any;
    var player = win.neplayer(
      videoId,
      {
        width: '100%',
        errorDisplay: false,
      },
      function () {
        // 当播放器初始化完成时运行的回调函数
      },
    );
    hideMsg(`${key}-err`);
    if (_get(item, 'url', '')) {
      player.setDataSource({
        type: 'application/x-mpegURL',
        src: _get(item, 'url', ''),
      });
      player.play();
      player.on('waiting', function () {
        console.log('wating');
        showMsg(`${key}-err`, '正在加载数据');
      });
      player.on('canplay', function () {
        // 播放准备就绪的时候，隐藏错误提示元素
        // errorElement.style.display = "none";
        console.log('播放准备就绪，点击播放按钮开始播放');
        hideMsg(`${key}-err`);
        player.play();
      });
      player.on('canplaythrough', function () {
        console.log('canplaythrough');
      });
      player.on('playing', function () {
        hideMsg(`${key}-err`);
        console.log('playing');
        setDelay(0);
      });

      player.onError(function (err: any) {
        console.log(err.errCode);
        console.log(err.errMsg);
        // if (err.errCode == '7' || err.errCode == '4') {
        //   if (retryCount > 7) {
        //     showMsg(`${key}-err`, '网络异常，请稍后刷新重试');
        //     return;
        //   }
        //   //拉流超时
        //   player.refresh();
        //   setRetryCount((c) => c + 1);
        // }
      });
    } else {
      player.setDataSource('');
      // video.src = '';
    }
  }, [url]);

  function showMsg(id: string, msg: string) {
    const ele: any = document.getElementById(id);
    ele.style.display = 'block';
    const ele1: any = document.getElementById(`${id}-span`);
    ele1.innerHTML = msg;
  }
  const getHeight = () => {
    // const length = _get(selectedUrlData, 'length', 0);
    // if (length === 1) {
    //   return 600;
    // }
    // if (length === 2) {
    //   return 500;
    // }
    // if (length < 5) {
    //   return 360;
    // }
    return '80vh';
  };

  useEffect(() => {
    if (retryCount > 9) {
      setDelay(0);
    }
  }, [retryCount]);

  console.log(retryCount, 'retryCount');

  async function handleRefresh(isAuto?: boolean) {
    if (isCounting && isAuto !== true) {
      return message.info('请10秒钟后再刷新');
    }
    if (isAuto && delay !== 0) {
      setRetryCount((c) => c + 1);
    }

    setIsCounting(true);
    const key = `video-${_get(item, 'carId', '')}`;
    showMsg(`${key}-err`, '刷新中');
    var win = window as any;
    var player = win.neplayer(
      `video-${item.carId}`,
      {
        width: '100%',
        errorDisplay: false,
      },
      function () {
        // 当播放器初始化完成时运行的回调函数
      },
    );

    let oriUrl = '';
    let url = '';
    let query = {
      carId: _get(item, 'carId', ''),
      cameraNum: _get(item, 'id', ''),
      carSchoolId: _get(item, 'carSchoolId', ''),
      schoolId: _get(item, 'schoolId', ''),
    };
    const statusRes = await _getCarVideoStatus({ ...query, vid: _get(item, 'vid') });
    const status = _get(statusRes, 'data.status', '');
    if (status === '1') {
      //直播中
      player.refresh();
    } else {
      const res = await _getCarVideo(query);
      oriUrl = _get(res, 'data.hlsPullUrl', '');
      const vid = _get(res, 'data.vid', '');
      if (oriUrl) {
        const oriUrlArr = oriUrl.split(/\:/);
        url = document.location.protocol === 'https:' ? `https:${_get(oriUrlArr, '1', '')}` : oriUrl; // 视频播放地址是否https根据当前环境是否https切换
        player.setDataSource({
          type: 'application/x-mpegURL',
          src: url,
        });
        player.play();

        setVideoUrl(url);
        setCarObj({ ...item, url, vid });
        // setUrlObj({ ...urlObj, [_get(item, 'key', '')]: { url, vid } });
      }
      // eslint-disable-next-line no-useless-escape
    }
  }
  useEffect(() => {
    setDelay(6000);
  }, []);

  useInterval(() => {
    handleRefresh(true);
  }, delay);

  return (
    <div
      className="flex mt10"
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        border: 'solid 1px #f0f0f0',
        height: getHeight(),
        width,
      }}
    >
      <div style={{ alignSelf: 'flex-end', display: 'flex', justifyContent: 'space-between', paddingLeft: 20 }}>
        <span style={{ fontWeight: 'bold' }}>{`${item.text}`}</span>
        <span>
          {item.url && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRefresh(false);
              }}
            >
              <ReloadOutlined style={{ alignSelf: 'flex-end', margin: 5 }} />
              刷新
            </span>
          )}
          <CloseOutlined
            style={{ alignSelf: 'flex-end', margin: 5 }}
            onClick={async () => {
              setIsCounting(false);

              await _stopCarVideo({
                carId: _get(item, 'carId', ''),
                cameraNum: 1,
                carSchoolId: _get(item, 'carSchoolId', ''),
                schoolId: _get(item, 'schoolId', ''),
              });
              // setCheckedNodes(checkedNode);
              var win = window as any;
              var player = win.neplayer(
                `video-${item.carId}`,
                {
                  width: '100%',
                },
                function () {
                  // 当播放器初始化完成时运行的回调函数
                },
              );
              player.release();
              setVideoUrl('');
            }}
          />
        </span>
      </div>

      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <video
          style={{ width: '100%', height: '100%' }}
          id={`video-${item.carId}`}
          className="video-js vjs-big-play-centered"
          // webkit-playsinline
          // playsinline
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="landscape"
        ></video>
        <div
          id={`video-${item.carId}-err`}
          style={{
            width: '100%',
            position: 'absolute',
            bottom: '40%',
            textAlign: 'center',
            color: 'white',
            fontSize: 20,
            display: 'none',
          }}
        >
          <span id={`video-${item.carId}-err-span`}></span>
          <span id={`video-${item.carId}-err-icon`}>
            <LoadingOutlined />
          </span>
        </div>
      </div>

      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <span>{`${item.text}`}</span>
        {/* <ExpandOutlined
          style={{ marginTop: 5 }}
          className="ml20"
          onClick={() => {
            // setCount((c: any) => c + 1);
            setVideoDetailVisible();
          }}
        /> */}
        {item.url && (
          <ReloadOutlined
            style={{ marginLeft: 10, marginTop: 5 }}
            onClick={debounce(
              () => {
                handleRefresh(false);
              },
              10000,
              { leading: true },
            )}
          />
        )}
      </Row>
    </div>
  );
}
