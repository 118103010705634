import { Layout, Menu } from 'antd';
import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './Navigation.module.css';
import { menus } from './menus';
import { IeOutlined } from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;

function Navigation(props) {
  const { collapsed, setCollapsed } = props;
  const [currentPath, setCurrentPath] = useState(get(props, 'location.pathname'));
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    _findDefaultOpenKeys();
  }, []);

  useEffect(() => {
    setCurrentPath(get(props, 'location.pathname'));
  }, [get(props, 'location.pathname')]);

  function _onOpenChange(currentOpenKeys) {
    setOpenKeys(currentOpenKeys);
  }

  function _findDefaultOpenKeys() {
    const defaultOpenKeys = [];
    helper(menus, []);
    setOpenKeys(defaultOpenKeys);

    function helper(arr, parentKey) {
      for (let item of arr) {
        if (item.path === currentPath) {
          Object.assign(defaultOpenKeys, parentKey);
          return;
        }
        helper(get(item, 'subs', []), [...parentKey, item.key]);
      }
    }
  }

  function _generateSubMenus({ icon, text, path, subs, key }) {
    if (!subs) {
      return (
        <Menu.Item key={key}>
          <Link to={path}>
            {icon && icon()}
            <span>{text}</span>
          </Link>
        </Menu.Item>
      );
    }

    return (
      <SubMenu
        key={key}
        title={
          <span>
            {icon && icon()}
            <span>{text}</span>
          </span>
        }
      >
        {subs.map(_generateSubMenus)}
      </SubMenu>
    );
  }

  return (
    <Sider
      breakpoint="md"
      onBreakpoint={(broken) => {
        setCollapsed(broken);
      }}
      width={220}
      collapsedWidth={0}
      zeroWidthTriggerStyle
      collapsible
      trigger={null}
      collapsed={collapsed}
      style={{ background: '#28303f' }}
    >
      <div className={styles['menuLogo']} id="logo">
        <IeOutlined style={{ color: '#fff' }} />
        <span className={styles.title}>网络学习</span>
      </div>
      <Menu
        style={{ background: '#28303f' }}
        openKeys={collapsed ? [] : openKeys}
        onOpenChange={_onOpenChange}
        theme="dark"
        defaultSelectedKeys={[currentPath]}
        selectedKeys={[currentPath]}
        mode="inline"
      >
        {menus.map(_generateSubMenus)}
      </Menu>
    </Sider>
  );
}

export default withRouter(Navigation);
