// 图片地址
export const IMG_URL = 'http://192.168.192.67:20111/';

// 用户中心地址
export const USER_CENTER_URL = window.REACT_APP_USER_CENTER_URL || process.env.REACT_APP_USER_CENTER_URL;

// 前端地址
export const LOCAL_URL = window.location.origin;

export const ERP_URL = window.REACT_APP_ERP_URL || process.env.REACT_APP_ERP_URL;
// 接口地址
export const API_URL = window.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

// 门户接口地址
export const PORTAL_URL = window.REACT_APP_PORTAL_URL || process.env.REACT_APP_PORTAL_URL;

// 部署时的上下文路径
export const PUBLIC_URL = window.REACT_APP_PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL;

export const WENZHOU = window.wenzhou || 'https://wenzhou.yfdrive.cn';
export const SANMING = window.sanming || 'https://sanming.yfdrive.cn';
export const HANGZHOU = window.hangzhou || 'https://hangzhou.yfdrive.cn';
export const ALIYUN = window.aliyun || 'https://jx.welldrive.cn';
export const HUAWEIYUN = window.huaweiyun || 'https://school.welldrive.cn';

// 核心业务接口前缀
export const CORE_PREFIX = '/api/jp-train-core-svc';

// 非核心业务接口前缀
export const NOT_CORE_PREFIX = '/api/jp-train-noncore-svc';

// 用户中心接口前缀
export const USER_CENTER_PREFIX = '/api/usercenter';
export const TZ_CLIENT_ID = window.tzClientId || '1719455542743';
export const TZ_CLIENT_SECRET = window.tzClientSecret || 'ae1ead1b607287787af06717c1fbaac4';

export const USERNAME = window.tzU || 'tzvideo';
export const PASSWORD = window.tzD || 'Well@2024';
