import { _get, Auth } from 'utils';
import { PUBLIC_URL } from 'constants/env';
import { useHistory } from 'react-router';
import { _getBaseInfo, _getLogin, _getUserId } from './_api';

export const useLogin = () => {
  const h = useHistory();
  const login = async (username: string, password: string) => {
    const res = await _getLogin({
      username,
      password,
    });
    const access_token = _get(res, ['access_token']);
    console.log(access_token);
    if (!access_token) {
      // Toast.show('账号或密码错误');
      return null;
    }

    Auth.set('token', access_token);
    Auth.set('username', username);
    const refresh_token = _get(res, ['refresh_token']);
    Auth.set('refresh_token', refresh_token);
    const userInfo = await _getUserId({
      username,
    });
    const code = _get(userInfo, ['code']);
    if (code !== 200) {
      return;
    }
    const userId = _get(userInfo, ['data', 'id']);
    const name = _get(userInfo, ['data', 'name']);
    const companyId = _get(userInfo, ['data', 'companyId']);
    const companies = _get(userInfo, ['data', 'companies'], []) || [];
    const selectedCompany = companies.find((x: { companyId: string }) => (x.companyId = companyId));
    const schoolId = _get(selectedCompany, 'companyId');
    const schoolName = _get(selectedCompany, 'name');
    if (!schoolId) {
      return;
    }
    Auth.set('userId', userId);
    Auth.set('name', name);
    Auth.set('schoolId', schoolId);
    Auth.set('schoolName', schoolName);
    const stuInfo = await _getBaseInfo({
      userId,
    });
    const stuInfoCode = _get(stuInfo, ['code']);

    const cityCode = _get(stuInfo, ['data', 'cityCode'], '');
    const avatar = _get(stuInfo, ['data', 'headImgVO', 'head_img_url_show']);
    const mobilePhone = _get(stuInfo, ['data', 'phone']);
    const sid = _get(stuInfo, ['data', 'sid']);
    const traintype = _get(stuInfo, ['data', 'traintype']);
    Auth.set('traintype', traintype);
    Auth.set('avatar', avatar);
    Auth.set('mobilePhone', mobilePhone);
    Auth.set('cityCode', cityCode);
    Auth.set('provinceCode', cityCode ? cityCode.slice(0, 2) + '0000' : '');
    Auth.set('sid', sid);

    return companies;
    // Toast.show('登录成功');
    // h.push(`${PUBLIC_URL}distanceLearning/stuInfo`);
  };

  return login;
};
