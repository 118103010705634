import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Input, Form, Modal, Button, Checkbox, message } from 'antd';
import { useForceUpdate } from 'hooks';
import { _login } from './_api';
import { Auth } from 'utils';
import { USER_CENTER_URL } from 'constants/env';

export default function Timing(props) {
  const { onCancel } = props;
  const history = useHistory();
  const [ignore, forceUpdate] = useForceUpdate();

  async function _onFinish(values) {
    const res = await _login({
      account: get(values, 'username'),
      password: get(values, 'password'),
      vercode: get(values, 'checkCode'),
      cityCode: Auth.get('cityCode'),
      userId: Auth.get('userId'),
      schoolLabel: Auth.get('schoolLabel'),
      schoolId: Auth.get('schoolId'),
    });

    if (get(res, 'resultCode') === '0000') {
      onCancel();
      Auth.set('jpsUserId', get(res, 'resultData.jpsUserId'));
      window.open(get(res, 'resultData.url'));
      history.go(0);
    } else {
      message.error(get(res, 'message'));
      forceUpdate();
    }
  }

  useEffect(() => {
    const oReq = new XMLHttpRequest();
    oReq.open(
      'GET',
      `${USER_CENTER_URL}/portal/login/imageServletForPortal?cityCode=${Auth.get(
        'cityCode',
      )}&userId=${Auth.get('userId')}&ignore=${ignore}`,
      true,
    );
    oReq.setRequestHeader('token', Auth.get('token'));
    oReq.setRequestHeader('userId', Auth.get('userId'));
    oReq.setRequestHeader('Authorization', 'bearer' + Auth.get('token'));
    oReq.setRequestHeader('username', Auth.get('username'));
    oReq.setRequestHeader('cityCode', Auth.get('cityCode'));

    oReq.responseType = 'arraybuffer';
    oReq.onload = function (oEvent) {
      const arrayBuffer = oReq.response;
      if (arrayBuffer) {
        const u8 = new Uint8Array(arrayBuffer);
        const b64encoded = btoa(String.fromCharCode.apply(null, u8));
        if (!b64encoded) return;

        const mimetype = 'image/png';
        document.getElementById('verifyCode').src = 'data:' + mimetype + ';base64,' + b64encoded;
      }
    };
    oReq.send(null);
  }, [ignore]);

  return (
    <Modal title="登录计时平台" visible maskClosable={false} footer={null} onCancel={onCancel}>
      <Form onFinish={_onFinish}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: '请输入用户名！',
            },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="用户名" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: '请输入密码！',
            },
          ]}
        >
          <Input prefix={<LockOutlined />} type="password" placeholder="密码" />
        </Form.Item>
        <Form.Item>
          <Form.Item
            name="checkCode"
            style={{ display: 'inline-block' }}
            rules={[
              {
                required: true,
                message: '请输入验证码！',
              },
            ]}
          >
            <Input placeholder="验证码" />
          </Form.Item>
          <img
            id="verifyCode"
            width="100px"
            onClick={forceUpdate}
            alt=""
            style={{ float: 'right' }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            登录
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
