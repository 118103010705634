import { request } from 'services';

// 计时登录接口
// http://192.168.192.132:3000/project/11/interface/api/3601
export async function _login(query) {
  return request('/portal/login/loginForPortal', query, 'POST');
}

// 获取计时登录信息
// http://192.168.192.132:3000/project/11/interface/api/3594
export async function _ssoLogin(query) {
  return request('/portal/login/ssoLoginForPortal', query, 'GET');
}
