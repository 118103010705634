import { useState } from 'react';

export const useSearch: any = (initialState = {}) => {
  const [search, setSearch] = useState<any>(initialState);

  function _handleSearch(name: string, value: any) {
    setSearch((search: any) => ({ ...search, [name]: value }));
  }

  return [search, _handleSearch];
};
