import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Skeleton } from 'antd';
import { _getCarVideo, _getTreeData, _pageListSchCarLicnum, _stopCarVideo } from './_api';
import { _get } from 'utils';

const isHTTPS = document.location.protocol === 'https:';

const App = (props: any) => {
  const { searchText, setVideoUrl, setCarObj, videoUrl, carObj } = props;
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    setInitLoading(true);
    _getTreeData({ pid: -1, ptype: -1, page: 1, limit: 100, schoolId: searchText })
      .then((res: any) => {
        console.log(res);

        setData(_get(res, 'data.rows', []));
        setList(_get(res, 'data.rows', []));
      })
      .finally(() => {
        setInitLoading(false);
      });
  }, [searchText]);

  async function handleOpenVideo(item: any) {
    if (videoUrl) {
      await _stopCarVideo({
        carId: _get(carObj, 'carId', ''),
        cameraNum: 1,
        carSchoolId: _get(carObj, 'carSchoolId', ''),
        schoolId: _get(carObj, 'schoolId', ''),
      });
      // setCheckedNodes(checkedNode);
      var win = window as any;

      var player = win.neplayer(
        `video-${carObj.carId}`,
        {
          width: '100%',
        },
        function () {
          // 当播放器初始化完成时运行的回调函数
        },
      );
      player.release();
      setVideoUrl('');
    }
    console.log('open');
    const res = await _getCarVideo({
      carId: item.id,
      cameraNum: 1,
      carSchoolId: _get(item, 'schoolId', ''),
      schoolId: searchText,
    });
    let oriUrl = _get(res, 'data.hlsPullUrl', '');
    console.log(oriUrl);
    let vid = _get(res, 'data.vid', '');
    // eslint-disable-next-line no-useless-escape
    const oriUrlArr = oriUrl.split(/\:/);
    let url = isHTTPS ? `https:${_get(oriUrlArr, '1', '')}` : oriUrl; // 视频播放地址是否https根据当前环境是否https切换

    setCarObj({
      ...item,
      carId: item.id,
      cameraNum: 1,
      carSchoolId: _get(item, 'schoolId', ''),
      schoolId: searchText,
      url,
      vid,
    });
    setVideoUrl(url);
  }

  return (
    <div>
      <List
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={null}
        header={<div style={{ fontWeight: 'bold' }}>车牌号码</div>}
        dataSource={list}
        renderItem={(item: any) => {
          return (
            <List.Item
              actions={[
                <Button
                  key="list-loadmore-edit"
                  size="small"
                  type="link"
                  onClick={() => handleOpenVideo(item)}
                  disabled={!item.activeState}
                >
                  查看监控
                </Button>,
              ]}
            >
              <List.Item.Meta
                description={
                  <div>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        background: item.activeState === 1 ? '#0f0' : '#aaa',
                        borderRadius: 5,
                        display: 'inline-block',
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    />
                    <span style={{ fontWeight: 'bold' }}>{item?.text}</span>
                    <span style={{ marginLeft: 20, color: item.activeState === 1 ? 'rgb(0, 255, 0)' : 'unset' }}>
                      {item.activeState === 1 ? '在线' : '离线'}
                    </span>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default App;
