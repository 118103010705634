export const cityDataMap = {
  A: [
    {
      children: [
        {
          children: [],
          key: '340100',
          value: '合肥市',
        },
        {
          children: [],
          key: '340200',
          value: '芜湖市',
        },
        {
          children: [],
          key: '340300',
          value: '蚌埠市',
        },
        {
          children: [],
          key: '340400',
          value: '淮南市',
        },
        {
          children: [],
          key: '340500',
          value: '马鞍山市',
        },
        {
          children: [],
          key: '340600',
          value: '淮北市',
        },
        {
          children: [],
          key: '340700',
          value: '铜陵市',
        },
        {
          children: [],
          key: '340800',
          value: '安庆市',
        },
        {
          children: [],
          key: '341000',
          value: '黄山市',
        },
        {
          children: [],
          key: '341100',
          value: '滁州市',
        },
        {
          children: [],
          key: '341200',
          value: '阜阳市',
        },
        {
          children: [],
          key: '341300',
          value: '宿州市',
        },
        {
          children: [],
          key: '341500',
          value: '六安市',
        },
        {
          children: [],
          key: '341600',
          value: '亳州市',
        },
        {
          children: [],
          key: '341700',
          value: '池州市',
        },
        {
          children: [],
          key: '341800',
          value: '宣城市',
        },
      ],
      key: '340000',
      value: '安徽省',
    },
    {
      children: [{ children: [], key: '820000', value: '澳门特别行政区' }],
      key: '820000',
      value: '澳门特别行政区',
    },
  ],
  B: [
    {
      children: [
        {
          children: [],
          key: '110100',
          value: '北京市',
        },
      ],
      key: '110000',
      value: '北京市',
    },
  ],
  C: [
    {
      children: [
        {
          children: [],
          key: '500100',
          value: '重庆市',
        },
      ],
      key: '500000',
      value: '重庆市',
    },
  ],
  F: [
    {
      children: [
        {
          children: [],
          key: '350100',
          value: '福州市',
        },
        {
          children: [],
          key: '350200',
          value: '厦门市',
        },
        {
          children: [],
          key: '350300',
          value: '莆田市',
        },
        {
          children: [],
          key: '350400',
          value: '三明市',
        },
        {
          children: [],
          key: '350500',
          value: '泉州市',
        },
        {
          children: [],
          key: '350600',
          value: '漳州市',
        },
        {
          children: [],
          key: '350700',
          value: '南平市',
        },
        {
          children: [],
          key: '350800',
          value: '龙岩市',
        },
        {
          children: [],
          key: '350900',
          value: '宁德市',
        },
      ],
      key: '350000',
      value: '福建省',
    },
  ],
  G: [
    {
      children: [
        {
          children: [],
          key: '620100',
          value: '兰州市',
        },
        {
          children: [],
          key: '620200',
          value: '嘉峪关市',
        },
        {
          children: [],
          key: '620300',
          value: '金昌市',
        },
        {
          children: [],
          key: '620400',
          value: '白银市',
        },
        {
          children: [],
          key: '620500',
          value: '天水市',
        },
        {
          children: [],
          key: '620600',
          value: '武威市',
        },
        {
          children: [],
          key: '620700',
          value: '张掖市',
        },
        {
          children: [],
          key: '620800',
          value: '平凉市',
        },
        {
          children: [],
          key: '620900',
          value: '酒泉市',
        },
        {
          children: [],
          key: '621000',
          value: '庆阳市',
        },
        {
          children: [],
          key: '621100',
          value: '定西市',
        },
        {
          children: [],
          key: '621200',
          value: '陇南市',
        },
        {
          children: [],
          key: '622900',
          value: '临夏回族自治州',
        },
        {
          children: [],
          key: '623000',
          value: '甘南藏族自治州',
        },
      ],
      key: '620000',
      value: '甘肃省',
    },
    {
      children: [
        {
          children: [],
          key: '440100',
          value: '广州市',
        },
        {
          children: [],
          key: '440200',
          value: '韶关市',
        },
        {
          children: [],
          key: '440300',
          value: '深圳市',
        },
        {
          children: [],
          key: '440400',
          value: '珠海市',
        },
        {
          children: [],
          key: '440500',
          value: '汕头市',
        },
        {
          children: [],
          key: '440600',
          value: '佛山市',
        },
        {
          children: [],
          key: '440700',
          value: '江门市',
        },
        {
          children: [],
          key: '440800',
          value: '湛江市',
        },
        {
          children: [],
          key: '440900',
          value: '茂名市',
        },
        {
          children: [],
          key: '441200',
          value: '肇庆市',
        },
        {
          children: [],
          key: '441300',
          value: '惠州市',
        },
        {
          children: [],
          key: '441400',
          value: '梅州市',
        },
        {
          children: [],
          key: '441500',
          value: '汕尾市',
        },
        {
          children: [],
          key: '441600',
          value: '河源市',
        },
        {
          children: [],
          key: '441700',
          value: '阳江市',
        },
        {
          children: [],
          key: '441800',
          value: '清远市',
        },
        {
          children: [],
          key: '441900',
          value: '东莞市',
        },
        {
          children: [],
          key: '442000',
          value: '中山市',
        },
        {
          children: [],
          key: '445100',
          value: '潮州市',
        },
        {
          children: [],
          key: '445200',
          value: '揭阳市',
        },
        {
          children: [],
          key: '445300',
          value: '云浮市',
        },
      ],
      key: '440000',
      value: '广东省',
    },
    {
      children: [
        {
          children: [],
          key: '450100',
          value: '南宁市',
        },
        {
          children: [],
          key: '450200',
          value: '柳州市',
        },
        {
          children: [],
          key: '450300',
          value: '桂林市',
        },
        {
          children: [],
          key: '450400',
          value: '梧州市',
        },
        {
          children: [],
          key: '450500',
          value: '北海市',
        },
        {
          children: [],
          key: '450600',
          value: '防城港市',
        },
        {
          children: [],
          key: '450700',
          value: '钦州市',
        },
        {
          children: [],
          key: '450800',
          value: '贵港市',
        },
        {
          children: [],
          key: '450900',
          value: '玉林市',
        },
        {
          children: [],
          key: '451000',
          value: '百色市',
        },
        {
          children: [],
          key: '451100',
          value: '贺州市',
        },
        {
          children: [],
          key: '451200',
          value: '河池市',
        },
        {
          children: [],
          key: '451300',
          value: '来宾市',
        },
        {
          children: [],
          key: '451400',
          value: '崇左市',
        },
      ],
      key: '450000',
      value: '广西壮族自治区',
    },
    {
      children: [
        {
          children: [],
          key: '520100',
          value: '贵阳市',
        },
        {
          children: [],
          key: '520200',
          value: '六盘水市',
        },
        {
          children: [],
          key: '520300',
          value: '遵义市',
        },
        {
          children: [],
          key: '520400',
          value: '安顺市',
        },
        {
          children: [],
          key: '520500',
          value: '毕节市',
        },
        {
          children: [],
          key: '520600',
          value: '铜仁市',
        },
        {
          children: [],
          key: '522300',
          value: '黔西南布依族苗族自治州',
        },
        {
          children: [],
          key: '522600',
          value: '黔东南苗族侗族自治州',
        },
        {
          children: [],
          key: '522700',
          value: '黔南布依族苗族自治州',
        },
      ],
      key: '520000',
      value: '贵州省',
    },
  ],
  H: [
    {
      children: [
        {
          children: [],
          key: '460100',
          value: '海口市',
        },
        {
          children: [],
          key: '460200',
          value: '三亚市',
        },
        {
          children: [],
          key: '460300',
          value: '三沙市',
        },
        {
          children: [],
          key: '460400',
          value: '儋州市',
        },
      ],
      key: '460000',
      value: '海南省',
    },
    {
      children: [
        {
          children: [],
          key: '130100',
          value: '石家庄市',
        },
        {
          children: [],
          key: '130200',
          value: '唐山市',
        },
        {
          children: [],
          key: '130300',
          value: '秦皇岛市',
        },
        {
          children: [],
          key: '130400',
          value: '邯郸市',
        },
        {
          children: [],
          key: '130500',
          value: '邢台市',
        },
        {
          children: [],
          key: '130600',
          value: '保定市',
        },
        {
          children: [],
          key: '130700',
          value: '张家口市',
        },
        {
          children: [],
          key: '130800',
          value: '承德市',
        },
        {
          children: [],
          key: '130900',
          value: '沧州市',
        },
        {
          children: [],
          key: '131000',
          value: '廊坊市',
        },
        {
          children: [],
          key: '131100',
          value: '衡水市',
        },
      ],
      key: '130000',
      value: '河北省',
    },
    {
      children: [
        {
          children: [],
          key: '410100',
          value: '郑州市',
        },
        {
          children: [],
          key: '410200',
          value: '开封市',
        },
        {
          children: [],
          key: '410300',
          value: '洛阳市',
        },
        {
          children: [],
          key: '410400',
          value: '平顶山市',
        },
        {
          children: [],
          key: '410500',
          value: '安阳市',
        },
        {
          children: [],
          key: '410600',
          value: '鹤壁市',
        },
        {
          children: [],
          key: '410700',
          value: '新乡市',
        },
        {
          children: [],
          key: '410800',
          value: '焦作市',
        },
        {
          children: [],
          key: '410900',
          value: '濮阳市',
        },
        {
          children: [],
          key: '411000',
          value: '许昌市',
        },
        {
          children: [],
          key: '411100',
          value: '漯河市',
        },
        {
          children: [],
          key: '411200',
          value: '三门峡市',
        },
        {
          children: [],
          key: '411300',
          value: '南阳市',
        },
        {
          children: [],
          key: '411400',
          value: '商丘市',
        },
        {
          children: [],
          key: '411500',
          value: '信阳市',
        },
        {
          children: [],
          key: '411600',
          value: '周口市',
        },
        {
          children: [],
          key: '411700',
          value: '驻马店市',
        },
      ],
      key: '410000',
      value: '河南省',
    },
    {
      children: [
        {
          children: [],
          key: '230100',
          value: '哈尔滨市',
        },
        {
          children: [],
          key: '230200',
          value: '齐齐哈尔市',
        },
        {
          children: [],
          key: '230300',
          value: '鸡西市',
        },
        {
          children: [],
          key: '230400',
          value: '鹤岗市',
        },
        {
          children: [],
          key: '230500',
          value: '双鸭山市',
        },
        {
          children: [],
          key: '230600',
          value: '大庆市',
        },
        {
          children: [],
          key: '230700',
          value: '伊春市',
        },
        {
          children: [],
          key: '230800',
          value: '佳木斯市',
        },
        {
          children: [],
          key: '230900',
          value: '七台河市',
        },
        {
          children: [],
          key: '231000',
          value: '牡丹江市',
        },
        {
          children: [],
          key: '231100',
          value: '黑河市',
        },
        {
          children: [],
          key: '231200',
          value: '绥化市',
        },
        {
          children: [],
          key: '232700',
          value: '大兴安岭地区',
        },
      ],
      key: '230000',
      value: '黑龙江省',
    },
    {
      children: [
        {
          children: [],
          key: '420100',
          value: '武汉市',
        },
        {
          children: [],
          key: '420200',
          value: '黄石市',
        },
        {
          children: [],
          key: '420300',
          value: '十堰市',
        },
        {
          children: [],
          key: '420500',
          value: '宜昌市',
        },
        {
          children: [],
          key: '420600',
          value: '襄阳市',
        },
        {
          children: [],
          key: '420700',
          value: '鄂州市',
        },
        {
          children: [],
          key: '420800',
          value: '荆门市',
        },
        {
          children: [],
          key: '420900',
          value: '孝感市',
        },
        {
          children: [],
          key: '421000',
          value: '荆州市',
        },
        {
          children: [],
          key: '421100',
          value: '黄冈市',
        },
        {
          children: [],
          key: '421200',
          value: '咸宁市',
        },
        {
          children: [],
          key: '421300',
          value: '随州市',
        },
        {
          children: [],
          key: '422800',
          value: '恩施土家族苗族自治州',
        },
      ],
      key: '420000',
      value: '湖北省',
    },
    {
      children: [
        {
          children: [],
          key: '430100',
          value: '长沙市',
        },
        {
          children: [],
          key: '430200',
          value: '株洲市',
        },
        {
          children: [],
          key: '430300',
          value: '湘潭市',
        },
        {
          children: [],
          key: '430400',
          value: '衡阳市',
        },
        {
          children: [],
          key: '430500',
          value: '邵阳市',
        },
        {
          children: [],
          key: '430600',
          value: '岳阳市',
        },
        {
          children: [],
          key: '430700',
          value: '常德市',
        },
        {
          children: [],
          key: '430800',
          value: '张家界市',
        },
        {
          children: [],
          key: '430900',
          value: '益阳市',
        },
        {
          children: [],
          key: '431000',
          value: '郴州市',
        },
        {
          children: [],
          key: '431100',
          value: '永州市',
        },
        {
          children: [],
          key: '431200',
          value: '怀化市',
        },
        {
          children: [],
          key: '431300',
          value: '娄底市',
        },
        {
          children: [],
          key: '433100',
          value: '湘西土家族苗族自治州',
        },
      ],
      key: '430000',
      value: '湖南省',
    },
  ],
  J: [
    {
      children: [
        {
          children: [],
          key: '220100',
          value: '长春市',
        },
        {
          children: [],
          key: '220200',
          value: '吉林市',
        },
        {
          children: [],
          key: '220300',
          value: '四平市',
        },
        {
          children: [],
          key: '220400',
          value: '辽源市',
        },
        {
          children: [],
          key: '220500',
          value: '通化市',
        },
        {
          children: [],
          key: '220600',
          value: '白山市',
        },
        {
          children: [],
          key: '220700',
          value: '松原市',
        },
        {
          children: [],
          key: '220800',
          value: '白城市',
        },
        {
          children: [],
          key: '222400',
          value: '延边朝鲜族自治州',
        },
      ],
      key: '220000',
      value: '吉林省',
    },
    {
      children: [
        {
          children: [],
          key: '320100',
          value: '南京市',
        },
        {
          children: [],
          key: '320200',
          value: '无锡市',
        },
        {
          children: [],
          key: '320300',
          value: '徐州市',
        },
        {
          children: [],
          key: '320400',
          value: '常州市',
        },
        {
          children: [],
          key: '320500',
          value: '苏州市',
        },
        {
          children: [],
          key: '320600',
          value: '南通市',
        },
        {
          children: [],
          key: '320700',
          value: '连云港市',
        },
        {
          children: [],
          key: '320800',
          value: '淮安市',
        },
        {
          children: [],
          key: '320900',
          value: '盐城市',
        },
        {
          children: [],
          key: '321000',
          value: '扬州市',
        },
        {
          children: [],
          key: '321100',
          value: '镇江市',
        },
        {
          children: [],
          key: '321200',
          value: '泰州市',
        },
        {
          children: [],
          key: '321300',
          value: '宿迁市',
        },
      ],
      key: '320000',
      value: '江苏省',
    },
    {
      children: [
        {
          children: [],
          key: '360100',
          value: '南昌市',
        },
        {
          children: [],
          key: '360200',
          value: '景德镇市',
        },
        {
          children: [],
          key: '360300',
          value: '萍乡市',
        },
        {
          children: [],
          key: '360400',
          value: '九江市',
        },
        {
          children: [],
          key: '360500',
          value: '新余市',
        },
        {
          children: [],
          key: '360600',
          value: '鹰潭市',
        },
        {
          children: [],
          key: '360700',
          value: '赣州市',
        },
        {
          children: [],
          key: '360800',
          value: '吉安市',
        },
        {
          children: [],
          key: '360900',
          value: '宜春市',
        },
        {
          children: [],
          key: '361000',
          value: '抚州市',
        },
        {
          children: [],
          key: '361100',
          value: '上饶市',
        },
      ],
      key: '360000',
      value: '江西省',
    },
  ],
  L: [
    {
      children: [
        {
          children: [],
          key: '210100',
          value: '沈阳市',
        },
        {
          children: [],
          key: '210200',
          value: '大连市',
        },
        {
          children: [],
          key: '210300',
          value: '鞍山市',
        },
        {
          children: [],
          key: '210400',
          value: '抚顺市',
        },
        {
          children: [],
          key: '210500',
          value: '本溪市',
        },
        {
          children: [],
          key: '210600',
          value: '丹东市',
        },
        {
          children: [],
          key: '210700',
          value: '锦州市',
        },
        {
          children: [],
          key: '210800',
          value: '营口市',
        },
        {
          children: [],
          key: '210900',
          value: '阜新市',
        },
        {
          children: [],
          key: '211000',
          value: '辽阳市',
        },
        {
          children: [],
          key: '211100',
          value: '盘锦市',
        },
        {
          children: [],
          key: '211200',
          value: '铁岭市',
        },
        {
          children: [],
          key: '211300',
          value: '朝阳市',
        },
        {
          children: [],
          key: '211400',
          value: '葫芦岛市',
        },
      ],
      key: '210000',
      value: '辽宁省',
    },
  ],
  N: [
    {
      children: [
        {
          children: [],
          key: '150100',
          value: '呼和浩特市',
        },
        {
          children: [],
          key: '150200',
          value: '包头市',
        },
        {
          children: [],
          key: '150300',
          value: '乌海市',
        },
        {
          children: [],
          key: '150400',
          value: '赤峰市',
        },
        {
          children: [],
          key: '150500',
          value: '通辽市',
        },
        {
          children: [],
          key: '150600',
          value: '鄂尔多斯市',
        },
        {
          children: [],
          key: '150700',
          value: '呼伦贝尔市',
        },
        {
          children: [],
          key: '150800',
          value: '巴彦淖尔市',
        },
        {
          children: [],
          key: '150900',
          value: '乌兰察布市',
        },
        {
          children: [],
          key: '152200',
          value: '兴安盟',
        },
        {
          children: [],
          key: '152500',
          value: '锡林郭勒盟',
        },
        {
          children: [],
          key: '152900',
          value: '阿拉善盟',
        },
      ],
      key: '150000',
      value: '内蒙古自治区',
    },
    {
      children: [
        {
          children: [],
          key: '640100',
          value: '银川市',
        },
        {
          children: [],
          key: '640200',
          value: '石嘴山市',
        },
        {
          children: [],
          key: '640300',
          value: '吴忠市',
        },
        {
          children: [],
          key: '640400',
          value: '固原市',
        },
        {
          children: [],
          key: '640500',
          value: '中卫市',
        },
      ],
      key: '640000',
      value: '宁夏回族自治区',
    },
  ],
  Q: [
    {
      children: [
        {
          children: [],
          key: '630100',
          value: '西宁市',
        },
        {
          children: [],
          key: '630200',
          value: '海东市',
        },
        {
          children: [],
          key: '632200',
          value: '海北藏族自治州',
        },
        {
          children: [],
          key: '632300',
          value: '黄南藏族自治州',
        },
        {
          children: [],
          key: '632500',
          value: '海南藏族自治州',
        },
        {
          children: [],
          key: '632600',
          value: '果洛藏族自治州',
        },
        {
          children: [],
          key: '632700',
          value: '玉树藏族自治州',
        },
        {
          children: [],
          key: '632800',
          value: '海西蒙古族藏族自治州',
        },
      ],
      key: '630000',
      value: '青海省',
    },
  ],
  S: [
    {
      children: [
        {
          children: [],
          key: '370100',
          value: '济南市',
        },
        {
          children: [],
          key: '370200',
          value: '青岛市',
        },
        {
          children: [],
          key: '370300',
          value: '淄博市',
        },
        {
          children: [],
          key: '370400',
          value: '枣庄市',
        },
        {
          children: [],
          key: '370500',
          value: '东营市',
        },
        {
          children: [],
          key: '370600',
          value: '烟台市',
        },
        {
          children: [],
          key: '370700',
          value: '潍坊市',
        },
        {
          children: [],
          key: '370800',
          value: '济宁市',
        },
        {
          children: [],
          key: '370900',
          value: '泰安市',
        },
        {
          children: [],
          key: '371000',
          value: '威海市',
        },
        {
          children: [],
          key: '371100',
          value: '日照市',
        },
        {
          children: [],
          key: '371300',
          value: '临沂市',
        },
        {
          children: [],
          key: '371400',
          value: '德州市',
        },
        {
          children: [],
          key: '371500',
          value: '聊城市',
        },
        {
          children: [],
          key: '371600',
          value: '滨州市',
        },
        {
          children: [],
          key: '371700',
          value: '菏泽市',
        },
      ],
      key: '370000',
      value: '山东省',
    },
    {
      children: [
        {
          children: [],
          key: '140100',
          value: '太原市',
        },
        {
          children: [],
          key: '140200',
          value: '大同市',
        },
        {
          children: [],
          key: '140300',
          value: '阳泉市',
        },
        {
          children: [],
          key: '140400',
          value: '长治市',
        },
        {
          children: [],
          key: '140500',
          value: '晋城市',
        },
        {
          children: [],
          key: '140600',
          value: '朔州市',
        },
        {
          children: [],
          key: '140700',
          value: '晋中市',
        },
        {
          children: [],
          key: '140800',
          value: '运城市',
        },
        {
          children: [],
          key: '140900',
          value: '忻州市',
        },
        {
          children: [],
          key: '141000',
          value: '临汾市',
        },
        {
          children: [],
          key: '141100',
          value: '吕梁市',
        },
      ],
      key: '140000',
      value: '山西省',
    },
    {
      children: [
        {
          children: [],
          key: '610100',
          value: '西安市',
        },
        {
          children: [],
          key: '610200',
          value: '铜川市',
        },
        {
          children: [],
          key: '610300',
          value: '宝鸡市',
        },
        {
          children: [],
          key: '610400',
          value: '咸阳市',
        },
        {
          children: [],
          key: '610500',
          value: '渭南市',
        },
        {
          children: [],
          key: '610600',
          value: '延安市',
        },
        {
          children: [],
          key: '610700',
          value: '汉中市',
        },
        {
          children: [],
          key: '610800',
          value: '榆林市',
        },
        {
          children: [],
          key: '610900',
          value: '安康市',
        },
        {
          children: [],
          key: '611000',
          value: '商洛市',
        },
      ],
      key: '610000',
      value: '陕西省',
    },
    {
      children: [
        {
          children: [],
          key: '310100',
          value: '上海市',
        },
      ],
      key: '310000',
      value: '上海市',
    },
    {
      children: [
        {
          children: [],
          key: '510100',
          value: '成都市',
        },
        {
          children: [],
          key: '510300',
          value: '自贡市',
        },
        {
          children: [],
          key: '510400',
          value: '攀枝花市',
        },
        {
          children: [],
          key: '510500',
          value: '泸州市',
        },
        {
          children: [],
          key: '510600',
          value: '德阳市',
        },
        {
          children: [],
          key: '510700',
          value: '绵阳市',
        },
        {
          children: [],
          key: '510800',
          value: '广元市',
        },
        {
          children: [],
          key: '510900',
          value: '遂宁市',
        },
        {
          children: [],
          key: '511000',
          value: '内江市',
        },
        {
          children: [],
          key: '511100',
          value: '乐山市',
        },
        {
          children: [],
          key: '511300',
          value: '南充市',
        },
        {
          children: [],
          key: '511400',
          value: '眉山市',
        },
        {
          children: [],
          key: '511500',
          value: '宜宾市',
        },
        {
          children: [],
          key: '511600',
          value: '广安市',
        },
        {
          children: [],
          key: '511700',
          value: '达州市',
        },
        {
          children: [],
          key: '511800',
          value: '雅安市',
        },
        {
          children: [],
          key: '511900',
          value: '巴中市',
        },
        {
          children: [],
          key: '512000',
          value: '资阳市',
        },
        {
          children: [],
          key: '513200',
          value: '阿坝藏族羌族自治州',
        },
        {
          children: [],
          key: '513300',
          value: '甘孜藏族自治州',
        },
        {
          children: [],
          key: '513400',
          value: '凉山彝族自治州',
        },
      ],
      key: '510000',
      value: '四川省',
    },
  ],
  T: [
    {
      children: [],
      key: '710000',
      value: '台湾省',
    },
    {
      children: [
        {
          children: [],
          key: '120100',
          value: '天津市',
        },
      ],
      key: '120000',
      value: '天津市',
    },
  ],
  X: [
    {
      children: [
        {
          children: [],
          key: '540100',
          value: '拉萨市',
        },
        {
          children: [],
          key: '540200',
          value: '日喀则市',
        },
        {
          children: [],
          key: '540300',
          value: '昌都市',
        },
        {
          children: [],
          key: '540400',
          value: '林芝市',
        },
        {
          children: [],
          key: '540500',
          value: '山南市',
        },
        {
          children: [],
          key: '540600',
          value: '那曲市',
        },
        {
          children: [],
          key: '542500',
          value: '阿里地区',
        },
      ],
      key: '540000',
      value: '西藏自治区',
    },
    {
      children: [
        {
          children: [],
          key: '810000',
          value: '香港特别行政区',
        },
      ],
      key: '810000',
      value: '香港特别行政区',
    },
    {
      children: [
        {
          children: [],
          key: '650100',
          value: '乌鲁木齐市',
        },
        {
          children: [],
          key: '650200',
          value: '克拉玛依市',
        },
        {
          children: [],
          key: '650400',
          value: '吐鲁番市',
        },
        {
          children: [],
          key: '650500',
          value: '哈密市',
        },
        {
          children: [],
          key: '652300',
          value: '昌吉回族自治州',
        },
        {
          children: [],
          key: '652700',
          value: '博尔塔拉蒙古自治州',
        },
        {
          children: [],
          key: '652800',
          value: '巴音郭楞蒙古自治州',
        },
        {
          children: [],
          key: '652900',
          value: '阿克苏地区',
        },
        {
          children: [],
          key: '653000',
          value: '克孜勒苏柯尔克孜自治州',
        },
        {
          children: [],
          key: '653100',
          value: '喀什地区',
        },
        {
          children: [],
          key: '653200',
          value: '和田地区',
        },
        {
          children: [],
          key: '654000',
          value: '伊犁哈萨克自治州',
        },
        {
          children: [],
          key: '654200',
          value: '塔城地区',
        },
        {
          children: [],
          key: '654300',
          value: '阿勒泰地区',
        },
      ],
      key: '650000',
      value: '新疆维吾尔自治区',
    },
  ],
  Y: [
    {
      children: [
        {
          children: [],
          key: '530100',
          value: '昆明市',
        },
        {
          children: [],
          key: '530300',
          value: '曲靖市',
        },
        {
          children: [],
          key: '530400',
          value: '玉溪市',
        },
        {
          children: [],
          key: '530500',
          value: '保山市',
        },
        {
          children: [],
          key: '530600',
          value: '昭通市',
        },
        {
          children: [],
          key: '530700',
          value: '丽江市',
        },
        {
          children: [],
          key: '530800',
          value: '普洱市',
        },
        {
          children: [],
          key: '530900',
          value: '临沧市',
        },
        {
          children: [],
          key: '532300',
          value: '楚雄彝族自治州',
        },
        {
          children: [],
          key: '532500',
          value: '红河哈尼族彝族自治州',
        },
        {
          children: [],
          key: '532600',
          value: '文山壮族苗族自治州',
        },
        {
          children: [],
          key: '532800',
          value: '西双版纳傣族自治州',
        },
        {
          children: [],
          key: '532900',
          value: '大理白族自治州',
        },
        {
          children: [],
          key: '533100',
          value: '德宏傣族景颇族自治州',
        },
        {
          children: [],
          key: '533300',
          value: '怒江傈僳族自治州',
        },
        {
          children: [],
          key: '533400',
          value: '迪庆藏族自治州',
        },
      ],
      key: '530000',
      value: '云南省',
    },
  ],
  Z: [
    {
      children: [
        {
          children: [],
          key: '330100',
          value: '杭州市',
        },
        {
          children: [],
          key: '330200',
          value: '宁波市',
        },
        {
          children: [],
          key: '330300',
          value: '温州市',
        },
        {
          children: [],
          key: '330400',
          value: '嘉兴市',
        },
        {
          children: [],
          key: '330500',
          value: '湖州市',
        },
        {
          children: [],
          key: '330600',
          value: '绍兴市',
        },
        {
          children: [],
          key: '330700',
          value: '金华市',
        },
        {
          children: [],
          key: '330800',
          value: '衢州市',
        },
        {
          children: [],
          key: '330900',
          value: '舟山市',
        },
        {
          children: [],
          key: '331000',
          value: '台州市',
        },
        {
          children: [],
          key: '331100',
          value: '丽水市',
        },
      ],
      key: '330000',
      value: '浙江省',
    },
  ],
};
