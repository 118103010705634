export type TCategory =
  | 'stu_drivetrain_status'
  | 'checkstatus_jg_type'
  | 'subject_code_class_type'
  | 'subjectcode_type'
  | 'trans_part_type';

export const HASH = {
  stu_drivetrain_status: [
    { label: '报名', value: '00' },
    { label: '学驾中', value: '01' },
    { label: '退学', value: '02' },
    { label: '结业', value: '03' },
    { label: '注销', value: '04' },
    { label: '转校', value: '05' },
    { label: '归档', value: '99' },
  ],
  checkstatus_jg_type: [],
  subject_code_class_type: [],
  subjectcode_type: [],
  trans_part_type: [],
};
