import React, { useEffect, useState } from 'react';
import { Row, Input, Select, Empty } from 'antd';
import { useLogin } from './useLogin';
import { Auth, _get } from 'utils';
import { _getListByLoginUser, _pageListSchCarLicnum } from './_api';
import ListCar from './ListCar';
import Monitor from './Monitor';
import { PASSWORD, USERNAME } from 'constants/env';
export default function VideoMonitor() {
  const token = Auth.get('token');
  const login = useLogin();

  const [selectOptions, setSelectOptions] = useState([]);
  const [searchText, setSearchText] = useState(undefined);
  const [videoUrl, setVideoUrl] = useState('');
  const [carObj, setCarObj] = useState({});
  useEffect(() => {
    login(USERNAME, PASSWORD).then((res: any) => {
      if (token) {
        // _getListByLoginUser({}).then((res: any) => {
        //   const data = _get(res, 'data', []);
        //   setSelectOptions(data.map((item: any) => ({ value: item.id, label: item.name })));
        // });
      }
    });
  }, [token, searchText]);

  return (
    <div
      style={{
        display: 'flex',
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ececec',
        height: '100vh',
      }}
    >
      <div
        style={{
          alignSelf: 'baseline',
          marginRight: 15,
          background: '#fff',
          height: ' 100%',
          padding: 15,
          overflow: 'auto',
          width: 325,
        }}
      >
        <Row>
          <Input.Group compact style={{ margin: '0 20px 20px 0', display: 'flex', alignItems: 'center' }}>
            <div>驾培机构：</div>
            <Select
              onSearch={(value: any) => {
                _getListByLoginUser({ name: value }).then((res: any) => {
                  const data = _get(res, 'data', []);
                  setSelectOptions(data.map((item: any) => ({ value: item.id, label: item.name })));
                });
              }}
              onChange={(value: any) => {
                setSearchText(value);
                setCarObj({});
                setVideoUrl('');
              }}
              options={selectOptions}
              showSearch
              filterOption={false}
              value={searchText}
              placeholder={'请输入驾培机构名称'}
            />
          </Input.Group>
          {/* <Button type="primary" className="mr20 mb20" onClick={handleSearch}>
            查询
          </Button> */}
        </Row>
        {searchText && (
          <ListCar
            searchText={searchText}
            setCarObj={setCarObj}
            setVideoUrl={setVideoUrl}
            carObj={carObj}
            videoUrl={videoUrl}
          />
        )}
      </div>
      <div style={{ flex: 1, background: '#fff', height: ' 100%' }}>
        <div style={{ fontSize: 18, fontWeight: 'bold', padding: 20 }}>视频监控</div>
        {videoUrl ? (
          <Monitor videoUrl={videoUrl} item={carObj} setVideoUrl={setVideoUrl} setCarObj={setCarObj} />
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
}
