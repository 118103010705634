import { postRequestTemp, request } from 'services';
import { CORE_PREFIX, TZ_CLIENT_ID, TZ_CLIENT_SECRET } from 'constants/env';
import { Auth } from 'utils';
export function _getLogin(query: {
  grant_type?: string; // 登录类型
  username: string; // 用户名/手机号
  password: string; // 	密码/验证码
  scop?: string; // 固定
}) {
  return postRequestTemp(
    `/uni/oauth/token?grant_type=password&client_id=${TZ_CLIENT_ID}&client_secret=${TZ_CLIENT_SECRET}&username=${query.username}&password=${query.password}`,
  );
}

export function _retrieveToken({ refresh_token }: { refresh_token: string }) {
  return postRequestTemp(`/uni/oauth/token`, {
    grant_type: 'refresh_token',
    client_id: TZ_CLIENT_ID,
    client_secret: TZ_CLIENT_SECRET,
    refresh_token: refresh_token,
  });
}
// api/usercenter/v1/company/listByLoginUser
export function _getListByLoginUser(query: { name?: string }) {
  return request(
    `/api/usercenter/v1/company/listByLoginUser`,
    query,
    'GET',

    {
      isOpenApi: false,
      mustAuthenticated: false,
      customHeader: {
        schoolId: null,
        componyId: null,
        sid: null,
        // username: query.username,
      },
    },
  );
}
// 获取userId
export function _getUserId(query: { username: string }) {
  return request(
    `/api/usercenter/user/info/front?username=${query.username}`,
    {},
    'GET',

    {
      isOpenApi: false,
      mustAuthenticated: false,
      customHeader: {
        schoolId: null,
        componyId: null,
        sid: null,
        username: query.username,
      },
    },
  );
}

//查询学员信息
export function _getBaseInfo(query: { userId: string }) {
  return request(
    // USER_CENTER_URL + `${CORE_PREFIX}/v1/student/getBaseInfo`,
    `${CORE_PREFIX}/v1/student/getBaseInfo`,
    { userId: query.userId },
    'GET',
    { mustAuthenticated: false, isOpenApi: false, customHeader: { schoolId: Auth.get('schoolId') } },
  );
}
// api/jp-train-noncore-svc/v1/schCarMonitor/pageListSchCarLicnum?licnum=&page=1&limit=10
export function _pageListSchCarLicnum(query: { licnum: string; schoolId: any }) {
  return request(
    `/api/jp-train-noncore-svc/v1/schCarMonitor/pageListSchCarLicnum?licnum=${query.licnum}&page=1&limit=999`,
    {},
    'GET',

    {
      isOpenApi: false,
      mustAuthenticated: false,
      customHeader: {
        schoolId: query.schoolId,
        componyId: query.schoolId,
        sid: null,
      },
    },
  );
}
export async function _getCarVideo(query: {
  carId: string;
  cameraNum: number; // 摄像头序号   1-内置； 2-外置
  carSchoolId: any;
  schoolId: string;
}) {
  return await request(`/api/jp-train-noncore-svc/v1/schCarMonitor/carVideo`, query, 'GET', {
    isOpenApi: false,
    mustAuthenticated: false,
    customHeader: {
      schoolId: query.schoolId,
      componyId: query.schoolId,
      sid: null,
    },
  });
}
// 车辆监控树
export async function _getTreeData(query: any) {
  return await request(`/api/jp-train-noncore-svc/v1/schCarMonitor/monitorTree`, query, 'GET', {
    isOpenApi: false,
    mustAuthenticated: false,
    customHeader: {
      schoolId: query.schoolId,
      componyId: query.schoolId,
      sid: null,
    },
  });
}
// 车辆视频监控播放状态
export async function _getCarVideoStatus(query: {
  carId: string;
  cameraNum: number; // 摄像头序号   1-内置； 2-外置
  carSchoolId: any;
  vid: string;
  schoolId: string;
}) {
  return await request(`/api/jp-train-noncore-svc/v1/schCarMonitor/selectVideo`, query, 'GET', {
    isOpenApi: false,
    mustAuthenticated: false,
    customHeader: {
      schoolId: query.schoolId,
      componyId: query.schoolId,
      sid: null,
    },
  });
}
//http://192.168.192.132:3000/project/183/interface/api/25805
// 车辆视频监控播放地址
export async function _stopCarVideo(query: {
  carId: string;
  cameraNum: number; // 摄像头序号   1-内置； 2-外置
  carSchoolId: any;
  schoolId: string;
}) {
  return await request(`/api/jp-train-noncore-svc/v1/schCarMonitor/carVideoStop`, query, 'GET', {
    isOpenApi: false,
    mustAuthenticated: false,
    customHeader: {
      schoolId: query.schoolId,
      componyId: query.schoolId,
      sid: null,
    },
  });
}
