import { useState, useEffect } from 'react';
import { get } from 'lodash';

export const useFetch = ({
  request = (query: any) => {},
  query = {} as any,
  depends = [] as any,
  callback = (data: any) => {},
  requiredFields = [] as any,
  forceCancel = false, // 取消请求
}) => {
  const [fetchStore, setFetchStore] = useState({
    res: null,
    isLoading: true,
    isError: false,
  } as any);

  useEffect(() => {
    let didCancel = false;
    // 如果没有传入必填字段则不触发请求 | 主动取消请求
    if (requiredFields.some((field: string) => query[field] == null) || forceCancel) {
      setFetchStore((fetchStore: any) => ({ ...fetchStore, isLoading: false }));
      return;
    }

    const fetchData = async () => {
      setFetchStore((fetchStore: any) => ({ ...fetchStore, isLoading: true, isError: false }));
      try {
        const res: any = await request(query);
        if (!didCancel) {
          setFetchStore((fetchStore: any) => ({
            ...fetchStore,
            isError: false,
            res,
            isLoading: false,
          }));
          get(res, 'data') && callback(get(res, 'data'));
        }
      } catch (error) {
        if (!didCancel) {
          setFetchStore((fetchStore: any) => ({ ...fetchStore, isError: true, isLoading: false }));
        }
      } finally {
        setFetchStore((fetchStore: any) => ({ ...fetchStore, isLoading: false }));
      }
    };

    fetchData();
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, depends);

  return {
    res: get(fetchStore, 'res'),
    isLoading: fetchStore.isLoading,
    isError: fetchStore.isError,
    data: get(fetchStore, 'res.data'),
  };
};
