import React from 'react';
import { Spin } from 'antd';

export function Loading() {
  return (
    <div style={{ textAlign: 'center', marginTop: '10rem' }}>
      <Spin size="large" />
    </div>
  );
}
