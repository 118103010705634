import { PUBLIC_URL } from 'constants/env';

export const menus = [
  {
    text: '我的课程',
    path: `${PUBLIC_URL}theory/myCourse`,
    key: `${PUBLIC_URL}theory/myCourse`,
  },

  {
    text: '学习记录',
    path: `${PUBLIC_URL}theory/learnRecord`,
    key: `${PUBLIC_URL}theory/learnRecord`,
  },
];
