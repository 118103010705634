import { getRequest } from 'services/userCenter';
import { request } from 'services';

// 查询角色下菜单，元素资源
// http://192.168.192.132:3000/project/98/interface/api/3356
export async function _getRoleResource(query) {
  const { id, ...params } = query;
  return getRequest(`/api/usercenter/role/${id}/resource?category=ERP`, params);
}

// 查询树形菜单列表
// http://192.168.192.132:3000/project/98/interface/api/4301
export async function _getMenuTree(query) {
  return getRequest(`/api/usercenter/menu/tree?category=ERP`, query);
}

// 根据菜单ID查询元素资源列表
// http://192.168.192.132:3000/project/98/interface/api/4329
export async function _getMenuElements(query) {
  const { id, ...params } = query;
  return getRequest(`/api/usercenter/menu/${id}/element`, params);
}

// 获取登录用户信息（基本信息，菜单，元素资源）
// http://192.168.192.132:3000/project/98/interface/api/3076
export async function _getUserInfo(query) {
  return getRequest(`/api/usercenter/user/front/info`, query);
}

// 区域服务
// http://192.168.192.132:3000/project/118/interface/api/5113
export function _getAreaAuto(query) {
  return request('/openapi/projectmgr/portal/front/area/getAreaInfoByIpAuto', query, 'GET');
}

//查询app最新下载地址
//http://192.168.192.132:3000/project/163/interface/api/21724
export async function _getAppDownLoadUrl(query) {
  return request('/openapi/usercenter/v1/appmanage/function/newest', query, 'GET');
}
