import { request } from 'services';
import { CORE_PREFIX } from 'constants/env';

// 查询字典列表
// http://192.168.192.132:3000/project/193/interface/api/17307
// interface IGetCode {
//   codeType?: string;
//   codeKey?: string;
//   parentCodeKey?: string;
//   pauseRequest?: boolean;
// }

export async function _getCode(query: any) {
  const { pauseRequest, ...params } = query;
  if (pauseRequest) return;
  return await request(`${CORE_PREFIX}/v1/sysbase/code/get`, params, 'GET');
}

// 获取驾校报审 科目列表 combo
// http://192.168.192.132:3000/project/193/interface/api/19764
export async function _getSubjectApply() {
  return await request(`${CORE_PREFIX}/v1/sysbase/code/getSchoolSubjectApplyCombo`, {}, 'GET');
}

// 获取驾校阶段 核实状态 combo
// http://192.168.192.132:3000/project/193/interface/api/19771
export async function _getApplyStatus() {
  return await request(`${CORE_PREFIX}/v1/sysbase/code/getSubjectApplyStatusCombo`, {}, 'GET');
}

// 获取驾校可训课程范围combo
// http://192.168.192.132:3000/project/193/interface/api/21080
export async function _getCourse() {
  return await request(`${CORE_PREFIX}/v1/sysbase/code/getSchoolTrainClassCombo`, {}, 'GET');
}
