import { useState } from 'react';
import { useInterval } from 'hooks';

export function useCountUp(seconds: number = 0) {
  const [isCounting, setIsCounting] = useState(false);
  const [count, setCount] = useState(seconds);
  const [isFirstCounting, setIsFirstCounting] = useState(true);

  useInterval(() => {
    if (!isCounting) return;
    setIsFirstCounting(false);
    setCount(count + 1);
  }, 1000);

  return { isFirstCounting, count, setIsCounting, isCounting };
}
