import React, { Suspense, lazy, useContext, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import BasicLayout from 'layouts/BasicLayout';
import TheoryLayout from 'layouts/TheoryLayout';
import { Loading } from 'components';
import { PUBLIC_URL } from 'constants/env';
import GlobalContext from 'globalContext';
import Login from 'pages/login';
import VideoMonitor from 'pages/videoMonitor';

export default function Routers() {
  const foo = [
    {
      path: '/home',
      component: lazy(() => import('pages/home')),
    },
    {
      path: '/product',
      component: lazy(() => import('pages/product')),
    },
    {
      path: '/about',
      component: lazy(() => import('pages/about')),
    },
    {
      path: '/question',
      component: lazy(() => import('pages/question')),
    },
    // TODO: 方便用户查看
    {
      path: '/exam',
      component: lazy(() => import('pages/exam')),
    },
  ];

  const theoryRouters = [
    {
      path: '/myCourse',
      component: lazy(() => import('pages/myCourse')),
    },
    {
      path: '/courseLearning',
      component: lazy(() => import('pages/courseLearning')),
    },
    {
      path: '/verifyPage',
      component: lazy(() => import('pages/verifyPage')),
    },
    {
      path: '/learnRecord',
      component: lazy(() => import('pages/learnRecord')),
    },
  ];

  const theoryRoutersPro = [
    {
      path: '/theory/myCourse',
      component: lazy(() => import('pages/myCourse')),
    },
    {
      path: '/theory/learnRecord',
      component: lazy(() => import('pages/learnRecord')),
    },
    // {
    //   path: '/theory/exam',
    //   component: lazy(() => import('pages/exam')),
    // },
  ];

  return (
    <Switch>
      <Route exact path={PUBLIC_URL}>
        <Redirect to={`${PUBLIC_URL}home`} />
      </Route>
      <Route exact path={`${PUBLIC_URL}login`}>
        <Login />
      </Route>

      <Route exact path={`${PUBLIC_URL}videoMonitor`}>
        <VideoMonitor />
      </Route>
      <BasicLayout>
        <Suspense fallback={<Loading />}>
          <Switch>
            {foo.map((x: any) => (
              <Route path={PUBLIC_URL + x.path.replace(/^\//, '')} key={x.path}>
                {<x.component />}
              </Route>
            ))}

            <TheoryLayout>
              <Switch>
                {[...theoryRouters, ...theoryRoutersPro].map((x: any) => (
                  <Route path={PUBLIC_URL + x.path.replace(/^\//, '')} key={x.path}>
                    {<x.component />}
                  </Route>
                ))}
              </Switch>
            </TheoryLayout>
          </Switch>
        </Suspense>
      </BasicLayout>
    </Switch>
  );
}
