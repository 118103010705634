import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'utils';
import { postRequestTemp } from 'services/userCenter';
import { getRequest } from 'services/userCenter';
import { request } from 'services';
import { useFetch } from 'hooks';
import { get } from 'lodash';
import { USER_CENTER_URL, LOCAL_URL, CORE_PREFIX } from 'constants/env';
import GlobalContext from 'globalContext';
import { PUBLIC_URL } from 'constants/env';
import { formatQueryParams } from 'utils/formatQueryParams';

export default function BasicLayout(props) {
  const history = useHistory();
  const {
    $setToken,
    $setSchoolId,
    $setUsername,
    $setUserIdString,
    $setRolesIds,
    $setCompanyId,
    $setCityCode,
    $setJpsUserId,
    $setSchoolLabel,
    $setUserId,
    $setOperatorName,
    $setIsStudent,
  } = useContext(GlobalContext);

  useEffect(() => {
    if (Auth.isAuthenticated()) {
      history.replace(`${PUBLIC_URL}`);
    }
  });

  useEffect(() => {
    const code = window.location.search.replace(/\?code=/, '');
    if (!Auth.isAuthenticated()) {
      if (code !== '') {
        // 如果未授权就发起获取token的请求
        postRequestTemp('/uni/oauth/token', {
          grant_type: 'authorization_code',
          client_id: '1603500352454',
          client_secret: '6d437f855f0af2c1b2c32c084ba5a2ac',
          code,
          redirect_uri: `${LOCAL_URL}${PUBLIC_URL}login`,
        }).then(async (res) => {
          const token = get(res, 'access_token'); // 用户中心返回的token
          Auth.set('token', token);
          $setToken(token);

          const options = {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              token,
            },
          };

          // 从用户中心获取username
          const uniUserRes = await fetch(USER_CENTER_URL + '/uni/user', options);
          const bar = await uniUserRes.json();
          Auth.set('username', get(bar, 'username'));
          $setUsername(get(bar, 'username'));

          getRequest(
            `/api/usercenter/userStudent/${get(bar, 'username')}/checkExistByUserName`,
            {},
            { username: get(bar, 'username') },
          ).then((res2) => {
            Auth.set('isStudent', get(res2, 'data', false));
            $setIsStudent(get(res2, 'data', false));
          });

          // 使用username从用户中心获取用户信息
          const userInfoRes = await getRequest(
            '/api/usercenter/user/info/front',
            {},
            { username: get(bar, 'username') },
          );

          if (get(userInfoRes, 'code') === 200) {
            const companies = get(userInfoRes, 'data.companies', []);
            const companyId = get(userInfoRes, 'data.companyId', '');
            const operatorName = get(userInfoRes, 'data.name', '');
            const selectedCompany = companies.find((x) => (x.companyId = companyId));
            const rolesIds = get(userInfoRes, 'data.companyRoles', [])
              .map((x) => x.id)
              .join(',');
            Auth.set('schoolId', get(selectedCompany, 'companyId', ''));
            $setSchoolId(get(selectedCompany, 'companyId'));

            Auth.set('companyId', companyId);
            $setCompanyId(companyId);

            Auth.set('operatorName', operatorName);
            $setOperatorName(operatorName);

            Auth.set('userIdString', get(userInfoRes, 'data.id'));
            $setUserIdString(get(userInfoRes, 'data.id'));

            Auth.set('rolesIds', rolesIds);
            $setRolesIds(rolesIds);

            // 使用schoolId和userIdString从ERP侧获取用户信息
            request(
              `/portal/login/${get(selectedCompany, 'companyCode')}/info`,
              { userId: get(userInfoRes, 'data.id') },
              'GET',
            ).then((loginInfoRes) => {
              if (get(loginInfoRes, 'resultCode') === '0000') {
                Auth.set('userId', get(loginInfoRes, 'resultData.userId'));
                $setUserId(get(loginInfoRes, 'resultData.userId'));

                get(loginInfoRes, 'resultData.jpsUserId')
                  ? Auth.set('jpsUserId', get(loginInfoRes, 'resultData.jpsUserId'))
                  : localStorage.removeItem('jpsUserId');
                $setJpsUserId(get(loginInfoRes, 'resultData.jpsUserId'));

                Auth.set('cityCode', get(loginInfoRes, 'resultData.cityCode'));
                $setCityCode(get(loginInfoRes, 'resultData.cityCode'));

                Auth.set('schoolLabel', get(loginInfoRes, 'resultData.schoolLable'));
                $setSchoolLabel(get(loginInfoRes, 'resultData.schoolLable'));
              }
            });

            const baseInfo = await fetch(
              USER_CENTER_URL +
                `${CORE_PREFIX}/v1/student/getBaseInfo` +
                formatQueryParams({ userId: get(userInfoRes, 'data.id', '') }),

              {
                method: 'GET',
                credentials: 'include',
                mode: 'cors',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: 'bearer' + Auth.get('token'),
                  username: Auth.get('username'),
                  schoolId: Auth.get('schoolId'),
                },
              },
            );
            const stuRes = await baseInfo.json();
            Auth.set('sid', get(stuRes, 'data.sid', ''));
            Auth.set('stuName', get(stuRes, 'data.name', ''));
          }
        });
      } else {
        window.location.href =
          `${USER_CENTER_URL}` +
          '/uni/oauth/authorize' +
          '?client_id=' +
          '1603500352454' +
          '&response_type=code&redirect_uri=' +
          `${LOCAL_URL}${PUBLIC_URL}login`;
      }

      return;
    }
  }, []);

  return null;
}
